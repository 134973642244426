<template>
  <v-card>
    <v-card-title>社員追加</v-card-title>
    <v-card-text>
      <v-messages class="mb-4" color="warning" :value="creator.error.messages" ></v-messages>
      <v-form ref="creator">
        <validation-provider v-slot="{ errors }" name="名前" rules="required|max:100">
          <v-text-field
            label="*名前"
            v-model="creator.name"
            dense
            :rules="[(v) => !!v || '名前 は必須です']"
            required
            counter
            :maxlength="100"
            :error-messages="errors"
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="メール" rules="required|max:100">
          <v-text-field
            label="*メール"
            v-model="creator.email"
            dense
            :rules="[(v) => !!v || 'メール は必須です']"
            required
            counter
            :maxlength="100"
            :error-messages="errors"
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="パスワード" rules="required|max:100">
          <v-text-field
            type="password"
            label="*パスワード"
            v-model="creator.password"
            dense
            :rules="[(v) => !!v || 'パスワード は必須です']"
            counter
            :maxlength="100"
            :error-messages="errors"
            required
          />
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="再入力" rules="required|max:100">
          <v-text-field
            type="password"
            label="再入力"
            :rules="[
              !!creator.confirmedPassword || '再入力 は必須です',
              creator.password === creator.confirmedPassword || 'パスワードが一致しません',
            ]"
            counter
            :maxlength="100"
            :error-messages="errors"
            v-model="creator.confirmedPassword"
          />
        </validation-provider>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn text color="warning" @click="$emit('cancel')"> 閉じる </v-btn>
      <v-spacer></v-spacer>

      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommitCraetor"> 追加 </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
  },

  data: () => ({
    creator: {
      show: false,
      name: '',
      email: '',
      password: '',
      confirmedPassword: '',
      error:{
        messages:[]
      },

      instance: null,
    },
  }),

  methods: {
    onLoaded() {},

    onCommitCraetor() {
      // validate
      if (!this.form.validate()) return;
      //
      let request = {
        name: this.creator.name,
        password: this.creator.password,
        email: this.creator.email,
      };
      this.postUpload('employee', request, [])
        .then((success) => {
          let data = success.data;
          // this.employees.push(data);

          this.$emit('commit', data);
          // this.creator.show = false;
        })
        .catch((error) => this.creator.error.messages=[error?.response?.data?.json?.message]);
    },
  },

  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {},
};
</script>