<template>
  <v-card flat>
    <v-card-title>
      <v-btn text color="warning" @click="$emit('cancel')"> 閉じる</v-btn>
      <v-spacer />
      パスワード変更
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onResetPassword">実行</v-btn>
    </v-card-title>
    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <v-sheet v-if="loaded" class="pa-4" max-width="300">
            <validation-provider v-slot="{ errors }" name="パスワード1" rules="required|max:64|confirmed:confirmation">
              <password v-model="editor.password" :secureLength="10" :toggle="true"></password>
              <XMessage :value="errors" />
            </validation-provider>
            <!-- <validation-provider v-slot="{ errors }" name="パスワード" rules="required|max:64|confirmed:confirmation">
              <v-text-field
                type="password"
                v-model="editor.password"
                class="mb-4"
                label="パスワード"
                :maxlength="64"
                :error-messages="errors"
                dense
              />
            </validation-provider> -->

            <validation-provider v-slot="{ errors }" name="再入力" rules="required|max:64" vid="confirmation">
              <v-text-field
                type="password"
                class="mt-8"
                v-model="editor.confirmedPassword"
                label="再入力"
                :maxlength="64"
                :error-messages="errors"
                dense
              />
            </validation-provider>
          </v-sheet>
        </template>
      </XSheet>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    observer: {
      type: Object,
      required: true,
    },

    employeeCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.editor.loading,
        disabled: () => this.editor.loading || this.initializing,
        canSubmit: () => !this.editor.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.editor.password = null;
          this.editor.confirmedPassword = null;
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
  },

  data: () => ({
    initializing: true,
    //
    editor: {
      loading: false,

      password: null,
      confirmedPassword: null,
    },
  }),

  methods: {
    onLoaded() {
      this.form.reset();
      this.initializing = false;
    },

    async onResetPassword() {
      //

      if (!(await this.form.validate())) return;
      //
      if (!confirm('変更してよろしいですか？')) return;

      this.editor.loading = true;
      this.put('employee/resetPassword2', {
        employeeCode: this.employeeCode,
        password: this.editor.password,
        ConfirmedPassword: this.editor.confirmedPassword,
      })
        .then(() => {
          this.showSuccess('パスワードを更新しました。');

          this.$emit('commit');
        })
        .catch((ex) => {
          console.error(ex);
          this.showError('パスワードの更新に失敗しました。パスワードの強度等をご確認ください');
        })
        .finally(() => {
          this.editor.loading = false;
        });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {},
};
</script>