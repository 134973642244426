var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("閉じる")]),_c('v-spacer'),_vm._v(" 支店情報編集 "),_c('v-spacer')],1),_c('v-card-text',[_c('XSheet',{attrs:{"loading":_vm.initializing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loaded = ref.loaded;
return [(loaded)?_c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"名称","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"名称","counter":"","maxlength":100,"error-messages":errors,"dense":"","outlined":""},model:{value:(_vm.editor.name),callback:function ($$v) {_vm.$set(_vm.editor, "name", $$v)},expression:"editor.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"ダミー","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-4",attrs:{"label":"ダミー","error-messages":errors,"dense":""},model:{value:(_vm.editor.isDummy),callback:function ($$v) {_vm.$set(_vm.editor, "isDummy", $$v)},expression:"editor.isDummy"}}),_c('v-select',{attrs:{"items":[
                    { text: '業務部', value: 1 << 1 },
                    { text: '営業部', value: 1 << 2 },
                    { text: 'Web', value: 1 << 3 },
                    { text: '旅館事業部', value: 1 << 4 } ],"label":"*種別","rules":[function (v) { return !!v || '種別は必須です'; }],"persistent-hint":"","error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.editor.sectionType),callback:function ($$v) {_vm.$set(_vm.editor, "sectionType", $$v)},expression:"editor.sectionType"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onUpdate}},[_vm._v("登録")])],1)],1)],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }