<template>
  <div>
    <v-tabs>
      <v-tab>社員管理</v-tab>
      <v-tab>支店・チーム管理</v-tab>
      <v-tab-item>
        <v-card outlined>
          <v-card-text>
            <v-data-table :headers="headers" :items="datas" :items-per-page="-1">
              <template v-slot:top>
                <ValidationObserver v-slot="observer">
                  <v-dialog v-model="creator.show" persistent max-width="750">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="ma-3" color="primary" dark v-bind="attrs" v-on="on" outlined small>
                        <v-icon left>mdi-database-plus-outline</v-icon>
                        社員追加
                      </v-btn>
                    </template>
                    <Create
                      :show="creator.show"
                      :observer="observer"
                      @commit="
                        creator.show = false;
                        load();
                      "
                      @cancel="creator.show = false"
                    />
                  </v-dialog>
                </ValidationObserver>
              </template>
              <template v-slot:body="{ items }">
                <tbody v-model="datas" is="draggable" tag="tbody">
                  <tr v-for="(item, i) in items" :key="i">
                    <td>{{ item.name }}</td>
                    <td>
                      <ul>
                        <li v-for="(email, index) of item.meta.emails.filter((a) => a.isSend)" :key="index">
                          {{ email.email }} ({{ email.type | emailType }})
                        </li>
                      </ul>
                      <!-- {{
                      item.meta.emails.filter((a) => a.isSend).map((a) => `${a.email} (${$options.filters.emailType(a.type)})`) | implode
                    }} -->
                    </td>

                    <td>
                      <ul>
                        <li v-for="(branch, index) of item.branchs" :key="index">
                          {{ branch.name }}
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li v-for="(team, index) of item.teams" :key="index">
                          {{ team.name }}
                        </li>
                      </ul>
                    </td>
                    <td>
                      {{ item.authorityTypes.map((a) => a.text) | implode }}
                    </td>
                    <td>
                      <v-icon
                        small
                        class="mr-2"
                        v-text="'mdi-account-arrow-up'"
                        @click="
                          roleEditor.code = item.code;
                          roleEditor.show = true;
                        "
                      />
                      <v-icon small class="mr-2" v-text="'mdi-pencil'" @click="onShowEditor(item)" />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-bottom-sheet v-model="roleEditor.show" scrollable inset>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" outlined>
              <v-icon v-text="'mdi-account-arrow-up'" left />
              権限設定
            </v-btn>
          </template>
          <RoleEdit
            :show="roleEditor.show"
            :employeeCode="roleEditor.code"
            @cancel="roleEditor.show = false"
            @commit="roleEditor.show = false"
          />
        </v-bottom-sheet>

        <ValidationObserver v-slot="observer">
          <v-bottom-sheet scrollable :value="editor.show" persistent inset>
            <Edit
              :show="editor.show"
              :observer="observer"
              :employeeCode="editor.code"
              @commit="
                editor.show = false;
                load();
              "
              @delete="editor.show = false"
              @cancel="editor.show = false"
            />
          </v-bottom-sheet>
        </ValidationObserver>
      </v-tab-item>

      <v-tab-item>
        <v-card outlined>
          <v-card-text>
            <draggable v-model="branchs" draggable=".draggable" class="list" tag="div" @start="onFileSortStart" @end="onFileSortEnd">
              <v-list-item v-for="branch in branchs" class="draggable" :key="`anchor${branch.code}`" two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-btn
                      text
                      small
                      @click="
                        branchEditor.code = branch.code;
                        branchEditor.show = true;
                      "
                    >
                      {{ branch.name }}</v-btn
                    ></v-list-item-title
                  >
                  <v-list-item-subtitle>
                    <v-chip
                      v-for="(team, j) in branch.teams"
                      :key="`team${j}`"
                      small
                      @click="
                        teamEditor.code = team.code;
                        teamEditor.show = true;
                      "
                    >
                      <span>
                        <XChipDummy v-if="team.isDummy" class="mr-2" />
                        {{ team.name }}
                      </span>
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </draggable>

            <!--  -->
            <!-- <v-expansion-panel-content :key="`content-branch${index}`">
                   
                  </v-expansion-panel-content> -->
            <!-- <v-expansion-panel> -->

            <!-- <v-expansion-panels>
              <vv-expansion-panel v-for="(branch, index) of branchs" :key="`branch${index}`">
                <v-expansion-panel-header>
                  <v-list-item-title>{{ branch.name }}</v-list-item-title>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-list-item class="ml-4" v-for="(team, j) in branch.teams" :key="`team${j}`">
                    <v-list-item-title>{{ team.name }}</v-list-item-title>
                    <v-list-item-icon> </v-list-item-icon>
                  </v-list-item>
                </v-expansion-panel-content>
              </vv-expansion-panel>
            </v-expansion-panels> -->
          </v-card-text>
        </v-card>

        <ValidationObserver v-slot="observer">
          <v-bottom-sheet scrollable :value="branchEditor.show" persistent inset>
            <BranchEdit
              :show="branchEditor.show"
              :observer="observer"
              :branchCode="branchEditor.code"
              @commit="
                branchEditor.show = false;
                load();
              "
              @delete="branchEditor.show = false"
              @cancel="branchEditor.show = false"
              @error="branchEditor.show = false"
            />
          </v-bottom-sheet>
        </ValidationObserver>

        <ValidationObserver v-slot="observer">
          <v-bottom-sheet scrollable :value="teamEditor.show" persistent inset>
            <TeamEdit
              :show="teamEditor.show"
              :observer="observer"
              :teamCode="teamEditor.code"
              @commit="
                teamEditor.show = false;
                load();
              "
              @delete="teamEditor.show = false"
              @cancel="teamEditor.show = false"
              @error="teamEditor.show = false"
            />
          </v-bottom-sheet>
        </ValidationObserver>
      </v-tab-item>
    </v-tabs>
  </div>
</template>


<script>
import Create from '@/components/employees/Create';
import Edit from '@/components/employees/Edit';

import BranchEdit from '@/components/branchs/Edit';
import TeamEdit from '@/components/teams/Edit';

import RoleEdit from '@/components/employees/roles/Role.vue';

export default {
  components: {
    Create,
    Edit,

    BranchEdit,
    TeamEdit,
    RoleEdit,
  },

  computed: {
    headers() {
      return [
        { text: '顧客名', align: 'start', value: 'name' },
        { text: 'メール設定', value: 'meta' },
        { text: '支店', value: 'branch' },
        { text: 'チーム', value: 'branch' },
        { text: '機能', value: 'authorityTypes' },
        { text: 'Actions', value: 'actions', sortable: false },
      ];
    },

    datas: {
      get() {
        return this.employees;
      },
      set(value) {
        value.forEach((a, i) => {
          a.dispOrder = i;
        });
        this.put('employee/sorts', {
          sorts: value.map((a) => ({
            employeeCode: a.code,
            dispOrder: a.dispOrder,
          })),
        }).then(() => {
          //
          this.employees = value;
        });
      },
    },
  },

  data: () => ({
    employees: [],
    branchs: [],

    creator: {
      show: false,
    },
    editor: {
      show: false,
    },

    branchEditor: {
      show: false,
      code: null,
    },
    teamEditor: {
      show: false,
      code: null,
    },

    roleEditor: {
      show: false,
      code: null,
    },
  }),

  watch: {},

  methods: {
    async load() {
      //
      let employees = [];
      // FIXME とりあえずの負荷軽減対策
      for (var i = 0; i < 20; i++) {
        const success = await this.get('employees', { page: i ,take:10});
        let data = success.data;

        employees = employees.concat(data);
      }
      this.employees = employees;


      // this.get('employees', {}).then((success) => {
      //   let data = success.data;
      //   this.employees = data;
      // });


      this.get('Branches', {}).then((success) => {
        let data = success.data;
        this.branchs = data;
      });
    },

    onLoaded() {
      this.load();
    },

    onShowEditor(employee) {
      this.editor.code = employee.code;
      this.editor.show = true;
    },

    onShowBranchEditor(branch) {
      this.branchEditor.code = branch.code;
      this.branchEditor.show = true;
    },

    onShowTeamEditor(team) {
      this.teamEditor.code = team.code;
      this.teamEditor.show = true;
    },

    onFileSortStart() {},
    onFileSortEnd() {
      // 設定後の並び順
      let codes = this.branchs.map((a) => a.code);

      this.put('branches/dispOrder', { branchCodes: codes })
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
        })
        .catch((error) => this.showErrorSnackbar(error));
    },
  },
  created() {
    this.onLoaded();
  },
};
</script>