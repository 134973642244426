var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" 閉じる ")]),_c('v-spacer'),_vm._v(" 社員編集 "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onCommitEditor}},[_vm._v(" 登録 ")])],1),_c('v-card-text',[_c('v-form',{ref:"editor",staticClass:"mt-4"},[_c('validation-provider',{attrs:{"name":"名前","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"名前","dense":"","rules":[function (v) { return !!v || '名前 は必須です'; }],"required":"","counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.editor.name),callback:function ($$v) {_vm.$set(_vm.editor, "name", $$v)},expression:"editor.name"}})]}}])}),_c('v-card-subtitle',[_vm._v("アカウント "+_vm._s(_vm.editor.email))]),_c('XFieldSet',{attrs:{"label":"支店"}},[_c('validation-provider',{attrs:{"name":"支店","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"item-text":"name","item-value":"code","items":_vm.branchs,"error-messages":errors,"multiple":"","dense":""},model:{value:(_vm.editor.branchs),callback:function ($$v) {_vm.$set(_vm.editor, "branchs", $$v)},expression:"editor.branchs"}})]}}])})],1),_c('XFieldSet',{attrs:{"label":"チーム"}},[_c('validation-provider',{attrs:{"name":"チーム","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"item-text":"name","item-value":"code","items":_vm.teams,"error-messages":errors,"multiple":"","dense":""},model:{value:(_vm.editor.teams),callback:function ($$v) {_vm.$set(_vm.editor, "teams", $$v)},expression:"editor.teams"}})]}}])})],1),_c('XFieldSet',{attrs:{"label":"初期選択時の都道府県"}},[_c('validation-provider',{attrs:{"name":"初期選択時の都道府県","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.prefs,"item-text":"name","item-value":"id","error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.editor.prefCode),callback:function ($$v) {_vm.$set(_vm.editor, "prefCode", $$v)},expression:"editor.prefCode"}})]}}])})],1),_c('XFieldSet',{attrs:{"label":"メール送信先機能"}},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.onAddEmailEditor}},[_c('v-icon',[_vm._v("mdi-database-plus-outline")]),_vm._v(" 追加")],1),_c('v-list',{attrs:{"three-line":""}},_vm._l((_vm.editor.emails),function(email,index){return _c('v-list-item',{key:index,attrs:{"dense":""}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"label":"送信","dense":""},model:{value:(email.isSend),callback:function ($$v) {_vm.$set(email, "isSend", $$v)},expression:"email.isSend"}})],1),_c('v-list-item-content',[_c('v-list-item-content',[_c('validation-provider',{attrs:{"name":"email","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"email","rules":[_vm.isEmail],"counter":"","maxlength":100,"error-messages":errors,"outlined":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onSendTestMail(email.email, email.type)}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-email-fast-outline')}})],1)]}}],null,true)},[_c('span',[_vm._v("テストメール送信")])])]},proxy:true}],null,true),model:{value:(email.email),callback:function ($$v) {_vm.$set(email, "email", $$v)},expression:"email.email"}})]}}],null,true)})],1),_c('v-list-item-content',[_c('validation-provider',{attrs:{"name":"メモ","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"メモ","counter":"","maxlength":100,"error-messages":errors},model:{value:(email.memo),callback:function ($$v) {_vm.$set(email, "memo", $$v)},expression:"email.memo"}})]}}],null,true)})],1),_c('v-list-item-content',[_c('XFieldSet',{attrs:{"label":"type"}},[_c('validation-provider',{attrs:{"name":"","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-chip-group',{attrs:{"active-class":"deep-purple--text text--accent-4","error-messages":errors},model:{value:(email.type),callback:function ($$v) {_vm.$set(email, "type", $$v)},expression:"email.type"}},[_c('v-chip',{attrs:{"value":1},domProps:{"textContent":_vm._s(_vm.$options.filters.emailType(1))}}),_c('v-chip',{attrs:{"value":1 << 1},domProps:{"textContent":_vm._s(_vm.$options.filters.emailType(1 << 1))}}),_c('v-chip',{attrs:{"value":1 << 2},domProps:{"textContent":_vm._s(_vm.$options.filters.emailType(1 << 2))}})],1)]}}],null,true)})],1)],1)],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.onDeleteEmailEditor(index)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)],1)}),1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":_vm.onDeleteEditor}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){_vm.resetPassword.show = true}}},[_vm._v("パスワードリセット")])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer){return [_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"500"},model:{value:(_vm.resetPassword.show),callback:function ($$v) {_vm.$set(_vm.resetPassword, "show", $$v)},expression:"resetPassword.show"}},[_c('ResetPassword',{attrs:{"observer":observer,"show":_vm.resetPassword.show,"employeeCode":_vm.employeeCode},on:{"commit":function($event){_vm.resetPassword.show = false},"cancel":function($event){_vm.resetPassword.show = false}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }