<template>
  <v-card>
    <v-card-title>
      <v-btn text color="warning" @click="$emit('cancel')"> 閉じる </v-btn>
      <v-spacer />
      社員編集
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommitEditor"> 登録 </v-btn>
    </v-card-title>

    <v-card-text>
      <v-form class="mt-4" ref="editor">
        <validation-provider v-slot="{ errors }" name="名前" rules="required|max:100">
          <v-text-field
            label="名前"
            v-model="editor.name"
            dense
            :rules="[(v) => !!v || '名前 は必須です']"
            required
            counter
            :maxlength="100"
            :error-messages="errors"
          />
        </validation-provider>

        <v-card-subtitle>アカウント {{ editor.email }}</v-card-subtitle>

        <!--
        <XFieldSet class="mb-4" label="機能">
         <validation-provider v-slot="{ errors }" name="機能" rules="">
            <v-chip-group
              active-class="deep-purple--text text--accent-4"
              v-model="editor.authorityTypes"
              multiple
              counter
              :error-messages="errors"
              column
              
            >
              <v-chip :value="1 << 1">フォーム</v-chip>
              <v-chip :value="1 << 2">フロー</v-chip>
              <v-chip :value="1 << 3">施設管理</v-chip>
              <v-chip :value="1 << 4">マスタデータ管理</v-chip>
              <v-chip :value="1 << 5">スケジュール</v-chip>
              <v-chip :value="1 << 6">売上進捗</v-chip>
              <v-chip :value="1 << 7">売上伝票</v-chip>
              <v-chip :value="1 << 8">営業ポイント成績表</v-chip>
              <v-chip :value="1 << 9">営業マージン集計表</v-chip>
              <v-chip :value="1 << 10">受注物件一覧</v-chip>
            </v-chip-group>

            <XMessage :value="errors" />
          </validation-provider>

        
        </XFieldSet>-->

        <XFieldSet label="支店">
          <validation-provider v-slot="{ errors }" name="支店" rules="required">
            <v-select
              item-text="name"
              item-value="code"
              :items="branchs"
              v-model="editor.branchs"
              :error-messages="errors"
              multiple
              dense
            />
          </validation-provider>
        </XFieldSet>

        <XFieldSet label="チーム">
          <validation-provider v-slot="{ errors }" name="チーム" rules="">
            <v-select item-text="name" item-value="code" :items="teams" v-model="editor.teams" :error-messages="errors" multiple dense />
          </validation-provider>
        </XFieldSet>

        <XFieldSet label="初期選択時の都道府県">
          <validation-provider v-slot="{ errors }" name="初期選択時の都道府県" rules="">
            <v-select
              v-model="editor.prefCode"
              :items="dataContext.prefs"
              item-text="name"
              item-value="id"
              :error-messages="errors"
              required
              outlined
              dense
            />
          </validation-provider>
        </XFieldSet>

        <XFieldSet label="メール送信先機能">
          <v-btn icon color="primary" @click="onAddEmailEditor"><v-icon>mdi-database-plus-outline</v-icon> 追加</v-btn>
          <v-list three-line>
            <v-list-item v-for="(email, index) in editor.emails" :key="index" dense>
              <v-list-item-action>
                <v-checkbox label="送信" v-model="email.isSend" dense />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-content>
                  <validation-provider v-slot="{ errors }" name="email" rules="max:100">
                    <v-text-field
                      label="email"
                      :rules="[isEmail]"
                      v-model="email.email"
                      counter
                      :maxlength="100"
                      :error-messages="errors"
                      outlined
                      dense
                    >
                      <template v-slot:prepend>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="onSendTestMail(email.email, email.type)" v-bind="attrs" v-on="on">
                              <v-icon v-text="'mdi-email-fast-outline'" />
                            </v-btn>
                          </template>
                          <span>テストメール送信</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </validation-provider></v-list-item-content
                >
                <v-list-item-content>
                  <validation-provider v-slot="{ errors }" name="メモ" rules="max:100">
                    <v-text-field
                      label="メモ"
                      v-model="email.memo"
                      counter
                      :maxlength="100"
                      :error-messages="errors"
                    /> </validation-provider
                ></v-list-item-content>
                <v-list-item-content>
                  <XFieldSet label="type">
                    <validation-provider v-slot="{ errors }" name="" rules="">
                      <v-chip-group active-class="deep-purple--text text--accent-4" v-model="email.type" :error-messages="errors">
                        <v-chip :value="1" v-text="$options.filters.emailType(1)" />
                        <v-chip :value="1 << 1" v-text="$options.filters.emailType(1 << 1)" />
                        <v-chip :value="1 << 2" v-text="$options.filters.emailType(1 << 2)" />
                      </v-chip-group>
                    </validation-provider>
                  </XFieldSet>
                </v-list-item-content>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="onDeleteEmailEditor(index)" small><v-icon>mdi-trash-can-outline</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </XFieldSet>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn icon color="red" @click="onDeleteEditor"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
      <v-spacer />
      <v-btn text @click="resetPassword.show = true" small>パスワードリセット</v-btn>
    </v-card-actions>

    <ValidationObserver v-slot="observer">
      <v-dialog persistent scrollable v-model="resetPassword.show" max-width="500">
        <ResetPassword
          :observer="observer"
          :show="resetPassword.show"
          :employeeCode="employeeCode"
          @commit="resetPassword.show = false"
          @cancel="resetPassword.show = false"
        />
      </v-dialog>
    </ValidationObserver>
  </v-card>
</template>


<script>
import ResetPassword from './ResetPassword';

export default {
  components: { ResetPassword },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },
    employeeCode: {
      type: String,
      required: true,
    },
  },

  computed: {
    form() {
      return {
        loading: () => this.editor.loading,
        disabled: () => this.editor.loading || this.initializing,
        canSubmit: () => !this.editor.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    teams() {
      //
      return this.branchs.filter((a) => this.editor.branchs.includes(a.code)).flatMap((a) => a.teams);
    },
  },

  data: () => ({
    //
    initializing: true,

    employees: [],

    branchs: [],

    editor: {
      loading: false,

      show: false,
      name: '',
      authorityTypes: [],
      avatar: {
        src: null,
        file: null,
      },
      branchs: [],
      teams: [],
      emails: [],
      instance: null,
    },

    resetPassword: {
      show: false,
    },
  }),

  methods: {
    isEmail(value) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Invalid e-mail.';
    },

    onLoaded() {
      this.initializing = true;

      let request = {
        employeeCode: this.employeeCode,
      };
      this.get('employee', request).then((success) => {
        let data = success.data;

        this.editor.code = data.code;
        this.editor.name = data.name;
        this.editor.email = data.email;
        this.editor.authorityTypes = data.authorityTypes.map((a) => a.value);

        this.editor.prefCode = data.prefCode;
        this.editor.emails = data.meta.emails;

        this.editor.branchs = data.branchs.map((a) => a.code);

        this.editor.teams = data.teams.map((a) => a.code);

        this.editor.show = true;

        this.initializing = false;
      });

      this.get('branches', request).then((success) => {
        let data = success.data;
        this.branchs = data;
      });
    },

    async onCommitEditor() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;
      //
      let request = {
        employeeCode: this.editor.code,
        authorityTypes: this.editor.authorityTypes,
        name: this.editor.name,
        prefCode: this.editor.prefCode,
        emails: this.editor.emails,
        // branchs: this.editor.branchs.map((a) => a.code),
        // teams: this.editor.teams.map((a) => a.code),
        branchs: this.editor.branchs,
        teams: this.editor.teams,
      };
      this.putUpload('employee', request, [this.editor.avatar.file]).then(() => {
        this.$emit('commit');
      });
    },

    onDeleteEditor() {
      //
      if (!confirm('削除してよろしいですか？')) return;
      //
      let request = {
        employeeCode: this.editor.code,
      };
      this.delete('employee', request).then(() => {
        this.employees = this.employees.filter((a) => a.code != this.editor.code);
        this.editor.show = false;
      });
    },

    onAddEmailEditor() {
      this.editor.emails.push({
        isSend: false,
        email: null,
        memo: null,
      });
    },
    onDeleteEmailEditor(index) {
      this.editor.emails.splice(index, 1);
    },

    async onSendTestMail(email, type) {
      //
      if (!confirm('テストメールを送信します。よろしいですか？')) return;

      this.post('mail/test', { email: email, type: type })
        .then(() => {
          this.showSuccess('テストメールの送信が完了しました');
        })
        .catch(() => {
          this.showError('テストメールの送信に失敗しました');
        });
    },
  },

  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {},
};
</script>