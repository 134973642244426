var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("社員追加")]),_c('v-card-text',[_c('v-messages',{staticClass:"mb-4",attrs:{"color":"warning","value":_vm.creator.error.messages}}),_c('v-form',{ref:"creator"},[_c('validation-provider',{attrs:{"name":"名前","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"*名前","dense":"","rules":[function (v) { return !!v || '名前 は必須です'; }],"required":"","counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.creator.name),callback:function ($$v) {_vm.$set(_vm.creator, "name", $$v)},expression:"creator.name"}})]}}])}),_c('validation-provider',{attrs:{"name":"メール","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"*メール","dense":"","rules":[function (v) { return !!v || 'メール は必須です'; }],"required":"","counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.creator.email),callback:function ($$v) {_vm.$set(_vm.creator, "email", $$v)},expression:"creator.email"}})]}}])}),_c('validation-provider',{attrs:{"name":"パスワード","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"*パスワード","dense":"","rules":[function (v) { return !!v || 'パスワード は必須です'; }],"counter":"","maxlength":100,"error-messages":errors,"required":""},model:{value:(_vm.creator.password),callback:function ($$v) {_vm.$set(_vm.creator, "password", $$v)},expression:"creator.password"}})]}}])}),_c('validation-provider',{attrs:{"name":"再入力","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"再入力","rules":[
            !!_vm.creator.confirmedPassword || '再入力 は必須です',
            _vm.creator.password === _vm.creator.confirmedPassword || 'パスワードが一致しません' ],"counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.creator.confirmedPassword),callback:function ($$v) {_vm.$set(_vm.creator, "confirmedPassword", $$v)},expression:"creator.confirmedPassword"}})]}}])})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" 閉じる ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onCommitCraetor}},[_vm._v(" 追加 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }