<template>
  <v-card>
    <v-card-title>
      <v-btn text color="warning" @click="$emit('cancel')">閉じる</v-btn>
      <v-spacer />
      支店情報編集
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <v-card outlined>
              <v-card-text>
                <validation-provider v-slot="{ errors }" name="名称" rules="max:100">
                  <v-text-field
                    class="mt-4"
                    label="名称"
                    v-model="editor.name"
                    counter
                    :maxlength="100"
                    :error-messages="errors"
                    dense
                    outlined
                  />
                </validation-provider>

                <validation-provider v-slot="{ errors }" name="ダミー" rules="">
                  <v-switch class="mt-4" label="ダミー" v-model="editor.isDummy" :error-messages="errors" dense />

                  <v-select
                    v-model="editor.sectionType"
                    :items="[
                      { text: '業務部', value: 1 << 1 },
                      { text: '営業部', value: 1 << 2 },
                      { text: 'Web', value: 1 << 3 },
                      { text: '旅館事業部', value: 1 << 4 },
                    ]"
                    label="*種別"
                    :rules="[(v) => !!v || '種別は必須です']"
                    persistent-hint
                    :error-messages="errors"
                    required
                    outlined
                    dense
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onUpdate">登録</v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </template>
      </XSheet>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    observer: {
      type: Object,
      required: true,
    },

    branchCode: {
      type: String,
      require: true,
    },

    show: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    form() {
      return {
        loading: () => this.editor.loading,
        disabled: () => this.editor.loading || this.initializing,
        canSubmit: () => !this.editor.loading && !this.initializing && !this.observer.invalid,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
  },

  data: () => ({
    initializing: true,

    editor: {
      loading: false,

      name: null,
      isDummy: false,
      sectionType: 1 << 1,
    },
  }),
  methods: {
    onLoaded() {
      console.log('branchCode', this.branchCode);
      //
      this.get('branch', { branchCode: this.branchCode })
        .then((success) => {
          //
          let data = success.data;
          this.editor.name = data.name;
          this.editor.isDummy = data.isDummy;
          this.editor.sectionType = data.sectionType;

          //
          this.initializing = false;

          //
        })
        .catch(() => {
          this.$emit('error');
          //
          this.$store.commit('setSnackbar', {
            show: true,
            color: 'warning',
            message: '支店情報が取得できませんでした',
          });
        });
    },

    async onUpdate() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;

      // 実績もここで更新させてしまう
      let request = {
        branchCode: this.branchCode,
        name: this.editor.name,
        isDummy: this.editor.isDummy,
        sectionType: this.editor.sectionType,
      };

      this.put('branch', request).then(() => {
        //
        this.$emit('commit');
        //
        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          message: '支店情報更新にしました',
        });
      });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    // this.onLoaded();
  },
};
</script>