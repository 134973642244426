var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',[_c('v-tab',[_vm._v("社員管理")]),_c('v-tab',[_vm._v("支店・チーム管理")]),_c('v-tab-item',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.datas,"items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer){return [_c('v-dialog',{attrs:{"persistent":"","max-width":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"color":"primary","dark":"","outlined":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-database-plus-outline")]),_vm._v(" 社員追加 ")],1)]}}],null,true),model:{value:(_vm.creator.show),callback:function ($$v) {_vm.$set(_vm.creator, "show", $$v)},expression:"creator.show"}},[_c('Create',{attrs:{"show":_vm.creator.show,"observer":observer},on:{"commit":function($event){_vm.creator.show = false;
                      _vm.load();},"cancel":function($event){_vm.creator.show = false}}})],1)]}}])})]},proxy:true},{key:"body",fn:function(ref){
                      var items = ref.items;
return [_c("draggable",{tag:"tbody",attrs:{"tag":"tbody"},model:{value:(_vm.datas),callback:function ($$v) {_vm.datas=$$v},expression:"datas"}},_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_c('ul',_vm._l((item.meta.emails.filter(function (a) { return a.isSend; })),function(email,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(email.email)+" ("+_vm._s(_vm._f("emailType")(email.type))+") ")])}),0)]),_c('td',[_c('ul',_vm._l((item.branchs),function(branch,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(branch.name)+" ")])}),0)]),_c('td',[_c('ul',_vm._l((item.teams),function(team,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(team.name)+" ")])}),0)]),_c('td',[_vm._v(" "+_vm._s(_vm._f("implode")(item.authorityTypes.map(function (a) { return a.text; })))+" ")]),_c('td',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-account-arrow-up')},on:{"click":function($event){_vm.roleEditor.code = item.code;
                        _vm.roleEditor.show = true;}}}),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-pencil')},on:{"click":function($event){return _vm.onShowEditor(item)}}})],1)])}),0)]}}])})],1)],1),_c('v-bottom-sheet',{attrs:{"scrollable":"","inset":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-account-arrow-up')}}),_vm._v(" 権限設定 ")],1)]}}]),model:{value:(_vm.roleEditor.show),callback:function ($$v) {_vm.$set(_vm.roleEditor, "show", $$v)},expression:"roleEditor.show"}},[_c('RoleEdit',{attrs:{"show":_vm.roleEditor.show,"employeeCode":_vm.roleEditor.code},on:{"cancel":function($event){_vm.roleEditor.show = false},"commit":function($event){_vm.roleEditor.show = false}}})],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer){return [_c('v-bottom-sheet',{attrs:{"scrollable":"","value":_vm.editor.show,"persistent":"","inset":""}},[_c('Edit',{attrs:{"show":_vm.editor.show,"observer":observer,"employeeCode":_vm.editor.code},on:{"commit":function($event){_vm.editor.show = false;
              _vm.load();},"delete":function($event){_vm.editor.show = false},"cancel":function($event){_vm.editor.show = false}}})],1)]}}])})],1),_c('v-tab-item',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('draggable',{staticClass:"list",attrs:{"draggable":".draggable","tag":"div"},on:{"start":_vm.onFileSortStart,"end":_vm.onFileSortEnd},model:{value:(_vm.branchs),callback:function ($$v) {_vm.branchs=$$v},expression:"branchs"}},_vm._l((_vm.branchs),function(branch){return _c('v-list-item',{key:("anchor" + (branch.code)),staticClass:"draggable",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){_vm.branchEditor.code = branch.code;
                      _vm.branchEditor.show = true;}}},[_vm._v(" "+_vm._s(branch.name))])],1),_c('v-list-item-subtitle',_vm._l((branch.teams),function(team,j){return _c('v-chip',{key:("team" + j),attrs:{"small":""},on:{"click":function($event){_vm.teamEditor.code = team.code;
                      _vm.teamEditor.show = true;}}},[_c('span',[(team.isDummy)?_c('XChipDummy',{staticClass:"mr-2"}):_vm._e(),_vm._v(" "+_vm._s(team.name)+" ")],1)])}),1)],1)],1)}),1)],1)],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer){return [_c('v-bottom-sheet',{attrs:{"scrollable":"","value":_vm.branchEditor.show,"persistent":"","inset":""}},[_c('BranchEdit',{attrs:{"show":_vm.branchEditor.show,"observer":observer,"branchCode":_vm.branchEditor.code},on:{"commit":function($event){_vm.branchEditor.show = false;
              _vm.load();},"delete":function($event){_vm.branchEditor.show = false},"cancel":function($event){_vm.branchEditor.show = false},"error":function($event){_vm.branchEditor.show = false}}})],1)]}}])}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer){return [_c('v-bottom-sheet',{attrs:{"scrollable":"","value":_vm.teamEditor.show,"persistent":"","inset":""}},[_c('TeamEdit',{attrs:{"show":_vm.teamEditor.show,"observer":observer,"teamCode":_vm.teamEditor.code},on:{"commit":function($event){_vm.teamEditor.show = false;
              _vm.load();},"delete":function($event){_vm.teamEditor.show = false},"cancel":function($event){_vm.teamEditor.show = false},"error":function($event){_vm.teamEditor.show = false}}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }